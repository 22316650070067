@import '../../variables';

@media screen and (min-width: 768px) {
    #login-container{
        height: 100vh;
        margin: 0;
        padding: 0;
        background: rgb(36,37,116);
        background: linear-gradient(90deg, rgba(36,37,116,1) 0%, rgba(22,46,164,1) 74%, rgba(9,50,187,1) 100%);
        background-image: url('/images/admin/login-bg.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        #login-data-container{
            /* UI Properties */
            padding: 4vh 4vw;
            background-color: #FFF;
            width: 80%;
            max-width: 500px;
            input{
                background-color: transparent;
            }
            #business-img{
                margin: 0 auto;
                display: block;
                width: 100%;
            }
            #login-title{
                margin: 5vh 0 0 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: bold;
                font-size: 2em;
            }
            #login-message{
                margin: 0 0 5vh 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: 500;
                font-size: 1.2em;
            }
            .form-control{
                text-align: center;
                margin: 20px 0;
                font-size: 1.3em;
                color: $text-gray;
            }
            #login-btn{
                display: block;
                margin: 3vh auto 3vh auto;
                font-size: 1.3em;
                width: 300px;
                color: $text-white;
            }
            #forgot-password{
                margin: 40px 0 0 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: 500;
                font-size: 1.1em;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
        
    }
}
@media screen and (max-width: 768px) {
    #login-container{
        height: 100vh;
        margin: 0;
        padding: 0;
        #login-data-container{
            /* UI Properties */
            padding: 4vh 4vw;
            #business-img{
                margin: 0 auto;
                display: block;
                width: 300px;
            }
            #login-title{
                margin: 5vh 0 0 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: bold;
                font-size: 2em;
            }
            #login-message{
                margin: 0 0 5vh 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: 500;
                font-size: 1.2em;
            }
            .form-control{
                text-align: center;
                margin: 20px 0;
                font-size: 1.3em;
                color: $text-gray;
            }
            #login-btn{
                display: block;
                margin: 3vh auto 3vh auto;
                font-size: 1.3em;
                width: 300px;
                color: $text-white;
            }
            #forgot-password{
                margin: 40px 0 0 0;
                padding: 0;
                display: block;
                color: $primary;
                text-align: center;
                font-weight: 500;
                font-size: 1.1em;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
        
    }
}